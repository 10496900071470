import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { AuthProvider } from "./context/User";
import { SidebarProvider } from "./context/SidebarContext";



ReactDOM.render(

  <AuthProvider>
    <SidebarProvider>

      <App />
    </SidebarProvider>
  </AuthProvider>,
  document.getElementById('root')

);


