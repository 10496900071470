import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  Grid,
  TextField,
  Button,
  useTheme,
  Box,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import axios from "axios";

const initialState = {
  advitisor_id: "",
  name: "",
  category: "",
  country: "",
  Tags: [],
  CampaignPhoto: "",
  description: "",
  url: "",
  payouts: {
    Registration: "",
    FTD: "",
    Qualified: "",
    Deposit: "",
  },
  image_url: "",
  horizontal_image_url: "",
  vertical_image_url: "",
  status: "",
  type: null,
  Model: null,
  paymentterms: null,
  KPI: null,
  CAP: null,
  validation_time: null,
  payment_frequency: null,
  joining_fee: null,
  reporting_type: null,
  conversion_flows: null,
  cashback: false,
  popunder_clickunder: false,
  behavioural_retargeting: false,
  sem: false,
  sms: false,
  email: false,
  brand_bidding: false,
  social_media_ads: false,
  incentive: false,
  toolbar: false,
  adult: false,
  youtube: false,
  _id: "",
  code: "",
};

function CreateCampaign() {
  const URL = process.env.REACT_APP_PROD_ADMIN_API;
  const API_KEY = process.env.REACT_APP_API_KEY;
  const [data, setData] = useState(initialState);
  const [advitisorDeatils, setAdvitisorDeatils] = useState([]);
  const [selectedAdvitisor_id, setSelectedAdvitisor_id] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSelectedAdvitisorChange = (event) =>
    setSelectedAdvitisor_id(event.target.value); // Fixed function to correctly set the selected advertiser ID
  const theme = useTheme();

  const advitisorDeatil = async () => {
    try {
      const url = `${URL}/api/advitisor/?api_key=${API_KEY}`;
      const res = await axios.get(url);
      setAdvitisorDeatils(res?.data);
    } catch (error) {
      console.log("This is error from advitisor data", error);
    }
  };
  const handleCheckboxChange = (name) => (event) => {
    setData({ ...data, [name]: event.target.checked });
  };
  const handleTagsChange = (event) => {
    const tagsArray = event.target.value.split(",").map(tag => tag.trim());
    setData({ ...data, Tags: tagsArray });
  };
  

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const apiUrl = `${URL}/campaign/?api_key=${API_KEY}`;

      const newData = { ...data, advitisor_id: selectedAdvitisor_id };
      const response = await axios.post(apiUrl, newData);

      if (response) {
        toast.success("Campaign created successfully!");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error creating campaign. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    advitisorDeatil();
  }, []);

  return (
    <>
      <Box>
        <p className="text-center">Add Campaign</p>{" "}
      </Box>

      <Paper
        elevation={3}
        sx={{
          margin: "auto", 
          padding: "20px",
          maxWidth: "1200px", 
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                variant="outlined"
                label="Name"
                value={data.name}
                onChange={(e) => setData({ ...data, name: e.target.value })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel>Select Advertiser</InputLabel>
              <Select
                value={selectedAdvitisor_id}
                onChange={handleSelectedAdvitisorChange}
                placeholder="Select Advertiser"
              >
                {advitisorDeatils.map((adv) => (
                  <MenuItem key={adv._id} value={adv._id}>
                    {adv.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel>Select Category</InputLabel>
              <Select
                value={data.category}
                onChange={(event) =>
                  setData({ ...data, category: event.target.value })
                }
                placeholder="Select Category"
              >
                {[
                  "Ecommerce",
                  "BFSI",
                  "Banking",
                  "Casino",
                  "CPL",
                  "CPR",
                  "CPS",
                  "CPD",
                  "Gambling",
                  "Crypto",
                  "Survey",
                ].map((cat) => (
                  <MenuItem key={cat} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel>Select Country</InputLabel>
              <Select
                value={data.country}
                onChange={(event) =>
                  setData({ ...data, country: event.target.value })
                }
                placeholder="Select Country"
              >
                {[
                  "India",
                  "Australia",
                  "Canada",
                  "Brazil",
                  "Vietnam",
                  "Russia",
                ].map((country) => (
                  <MenuItem key={country} value={country}>
                    {country}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                variant="outlined"
                label="img_url"
                value={data.image_url}
                onChange={(e) =>
                  setData({ ...data, image_url: e.target.value })
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel>Select Model</InputLabel>
              <Select
                value={data.Model}
                onChange={(e) => setData({ ...data, Model: e.target.value })}
                placeholder="Select Model"
              >
                <MenuItem value="CPL">CPL</MenuItem>
                <MenuItem value="CPD">CPD</MenuItem>
                <MenuItem value="CPR">CPR</MenuItem>
                <MenuItem value="CPA">CPA</MenuItem>
                <MenuItem value="CPS">CPS</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                value={data.status}
                placeholder="Select Status"
                onChange={(e) => setData({ ...data, status: e.target.value })}
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="paused">Paused</MenuItem>
                <MenuItem value="expired">Expired</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel>Type</InputLabel>
              <Select
                value={data.type}
                placeholder="Select Type"
                onChange={(e) => setData({ ...data, type: e.target.value })}
                variant="outlined"
              >
                <MenuItem value="Private">Private</MenuItem>
                <MenuItem value="Public">Public</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                placeholder="Enter Registration Value"
                variant="outlined"
                value={data.payouts.Registration}
                onChange={(e) =>
                  setData({
                    ...data,
                    payouts: { ...data.payouts, Registration: e.target.value },
                  })
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                placeholder="Enter FTD Value"
                variant="outlined"
                value={data.payouts.FTD}
                onChange={(e) =>
                  setData({
                    ...data,
                    payouts: { ...data.payouts, FTD: e.target.value },
                  })
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                placeholder="Enter Qualified Value"
                variant="outlined"
                value={data.payouts.Qualified}
                onChange={(e) =>
                  setData({
                    ...data,
                    payouts: { ...data.payouts, Qualified: e.target.value },
                  })
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                placeholder="Enter Deposit Value"
                variant="outlined"
                value={data.payouts.Deposit}
                onChange={(e) =>
                  setData({
                    ...data,
                    payouts: { ...data.payouts, Deposit: e.target.value },
                  })
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                placeholder="Enter Payment terms "
                variant="outlined"
                value={data.paymentterms}
                onChange={(e) =>
                  setData({
                    ...data,
                    paymentterms: e.target.value,
                  })
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                placeholder="Enter KPI "
                variant="outlined"
                value={data.KPI}
                onChange={(e) =>
                  setData({
                    ...data,
                    KPI: e.target.value,
                  })
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                placeholder="Enter CAP  "
                variant="outlined"
                value={data.CAP}
                onChange={(e) =>
                  setData({
                    ...data,
                    CAP: e.target.value,
                  })
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel>Archive</InputLabel>
              <Select
                value={data.archive}
                onChange={(e) => setData({ ...data, archive: e.target.value })}
                placeholder="Select Archive"
                variant="outlined"
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <FormControl component="fieldset">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={data.sem}
                          onChange={handleCheckboxChange("sem")}
                        />
                      }
                      label="SEM"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={data.sms}
                          onChange={handleCheckboxChange("sms")}
                        />
                      }
                      label="SMS"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={data.email}
                          onChange={handleCheckboxChange("email")}
                        />
                      }
                      label="Email"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl component="fieldset">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={data.brand_bidding}
                          onChange={handleCheckboxChange("brand_bidding")}
                        />
                      }
                      label="Brand Bidding"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={data.social_media_ads}
                          onChange={handleCheckboxChange("social_media_ads")}
                        />
                      }
                      label="Social Media Ads"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={data.incentive}
                          onChange={handleCheckboxChange("incentive")}
                        />
                      }
                      label="Incentive"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl component="fieldset">
                  <FormGroup className="d-flex justify-content-center w-100">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={data.toolbar}
                          onChange={handleCheckboxChange("toolbar")}
                        />
                      }
                      label="Toolbar"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={data.adult}
                          onChange={handleCheckboxChange("adult")}
                        />
                      }
                      label="Adult"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={data.youtube}
                          onChange={handleCheckboxChange("youtube")}
                        />
                      }
                      label="YouTube"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl
                  fullWidth
                  sx={{ marginBottom: { xs: "20px", md: "15px" } }}
                >
                  <TextField
                    label="desc"
                    variant="outlined"
                    value={data.description}
                    onChange={(e) =>
                      setData({ ...data, description: e.target.value })
                    }
                    placeholder="Enter Description"
                  />
                </FormControl>

                <FormControl fullWidth>
                  <TextField
                    label="Conversion Flow"
                    variant="outlined"
                    value={data.conversion_flows}
                    onChange={(e) =>
                      setData({ ...data, conversion_flows: e.target.value })
                    }
                    placeholder="Enter Conversion Flow"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <TextField
                label="Tags"
                variant="outlined"
                value={data.Tags.join(",")}
                onChange={handleTagsChange}
                placeholder="Enter tags separated by comma"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControl
              fullWidth
              sx={{ marginBottom: { xs: "20px", md: "15px" } }}
            >
              <TextField
                label="Enter Campaign photo url"
                variant="outlined"
                value={data.CampaignPhoto}
                onChange={(e) =>
                  setData({ ...data, CampaignPhoto: e.target.value })
                }
                placeholder="Enter Campaign photo url"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControl
              fullWidth
              sx={{ marginBottom: { xs: "20px", md: "15px" } }}
            >
              <TextField
                label="Enter horizontal_image url"
                variant="outlined"
                value={data.horizontal_image_url}
                onChange={(e) =>
                  setData({ ...data, horizontal_image_url: e.target.value })
                }
                placeholder="Enter  horizontal_image_url"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl
              fullWidth
              sx={{ marginBottom: { xs: "20px", md: "15px" } }}
            >
              <TextField
                label="Enter  vertical_image_url"
                variant="outlined"
                value={data.vertical_image_url}
                onChange={(e) =>
                  setData({ ...data, vertical_image_url: e.target.value })
                }
                placeholder="Enter   vertical_image_url"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl
              fullWidth
              sx={{ marginBottom: { xs: "20px", md: "15px" } }}
            >
              <TextField
                label="Enter url"
                variant="outlined"
                value={data.url}
                onChange={(e) => setData({ ...data, url: e.target.value })}
                placeholder="Enter  url"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl
              fullWidth
              sx={{ marginBottom: { xs: "20px", md: "15px" } }}
            >
              <TextField
                label="Enter validation time"
                variant="outlined"
                value={data.validation_time}
                onChange={(e) =>
                  setData({ ...data, validation_time: e.target.value })
                }
                placeholder="Enter validation time"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl
              fullWidth
              sx={{ marginBottom: { xs: "20px", md: "15px" } }}
            >
              <TextField
                label="Enter payment frequency"
                variant="outlined"
                value={data.payment_frequency}
                onChange={(e) =>
                  setData({ ...data, payment_frequency: e.target.value })
                }
                placeholder="Enter payment frequency"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl
              fullWidth
              sx={{ marginBottom: { xs: "20px", md: "15px" } }}
            >
              <TextField
                label="Enter joining fee"
                variant="outlined"
                value={data.joining_fee}
                onChange={(e) =>
                  setData({ ...data, joining_fee: e.target.value })
                }
                placeholder="Enter joining fee"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <Button
            onClick={handleSubmit}
              className="mx-auto d-block w-25"
              sx={{ bgcolor: loading ? "gray" : "" }}
              variant="contained"
              disabled={loading}
            >
              {" "}
              {loading ? "Submitting.." : "Submit"}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default CreateCampaign;
