import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import Table from "@mui/material/Table";
import Modal from "react-bootstrap/Modal";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import LinearProgress from "@mui/material/LinearProgress";

import * as XLSX from "xlsx";
import { getResFromLocalStorage } from "../service/localstorage";
import axios from "axios";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import PauseIcon from "@mui/icons-material/Pause";
import { fontWeight } from "@mui/system";
import toast, { Toaster } from "react-hot-toast";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import AddIcon from "@mui/icons-material/Add";

import TextField from "@mui/material/TextField";

const Offers = () => {
  const URL = process.env.REACT_APP_PROD_ADMIN_API;
  const API_KEY = process.env.REACT_APP_API_KEY;
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showEditCampaignModal, setShowEditCampaignModal] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedCampaignForDeletion, setSelectedCampaignForDeletion] =
    useState(null);
  const [isArchive, setIsArchive] = useState(false);
  const res = getResFromLocalStorage();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageData, setPageData] = useState([]);
  const [status, setStatus] = useState("");
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [manager, setManager] = useState("");
  const [country, setCountry] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [typeOffer, setTypeoffer] = useState("");
  const [statusCampagin, setStatusCampagin] = useState("");
  const [advitisorDeatils, setAdvitisorDeatils] = useState([]);
  const [selectedAdvitisor_id, setSelectedAdvitisor_id] = useState();
  const [offer_url, setOffer_url] = useState("");
  const [advitisorID, setAdvitisorID] = useState();
  const [deposit, setDeposit] = useState("");
  const [Deposit, setDepositLarge] = useState("");
  const [Registration, setRegistration] = useState("");
  const [FTD, setFTD] = useState("");
  const [Qualified, setQualified] = useState("");
  const [campaginPhoto, setCampaginPhoto] = useState("");
  const [model, setModel] = useState("");
  const [paymentterms, setPaymentterms] = useState("");
  const [KPI, setKPI] = useState("");
  const [CAP, setCAP] = useState("");
  const [file, setFile] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  var [event1_val, setEvent1_val] = useState();
  var [event2_val, setEvent2_val] = useState();
  var [event3_val, setEvent3_val] = useState();
  var [event4_val, setEvent4_val] = useState();
  const [campaignData, setCampaignData] = useState(null);
  const [newData, setNewData] = useState({
    campaign_id: "",
    name: "",
    CampaignPhoto: "",
    category: "",
    advitisor_id: "",
    url: "",
    description: "",
    image_url: "",
    status: "",
    type: "",
    horizontal_image_url: "",
    vertical_image_url: "",
  });

  const handleNameChange = (event) => setName(event.target.value);
  const handleDescChange = (event) => setDesc(event.target.value);
  const handleOfferUrlChange = (event) => setOffer_url(event.target.value);
  const handleCountryChange = (event) => setCountry(event.target.value);
  const handleTypeofferChange = (event) => setTypeoffer(event.target.value);
  const handleCategoryChange = (event) => setCategory(event.target.value);
  const handleDescriptionChange = (event) => setDescription(event.target.value);
  const handleImageUrlChange = (event) => setImageUrl(event.target.value);
  const handleStatusCampaginChange = (event) =>
    setStatusCampagin(event.target.value);
  const handleCampaginPhoto = (event) => setCampaginPhoto(event.target.value);

  const handleSelectedAdvitisorChange = (data) =>
    setSelectedAdvitisor_id(data?.target?.value);
  const [payoutVal, setPayoutVal] = useState();
  const [isPayoutVal, setIsPayoutVal] = useState(false);

  const showDeleteConfirmationDialog = (campaign) => {
    setSelectedCampaignForDeletion(campaign);
    setShowDeleteConfirmation(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const notesFile = (e) => {
    // setNoteProgress(0)
    // setNotesMsg('');
    let filePath = e.target.files[0];
    setFile(filePath);
  };

  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    setSelectedPhoto(file);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [status]);

  const addCamapgin = async () => {
    // handleClose();
    const data = {
      advitisor_id: selectedAdvitisor_id,
      name: name,
      category: category,
      country: country,
      url: offer_url,
      description: desc,
      image_url: imageUrl,
      status: statusCampagin,
      type: typeOffer,
      CampaignPhoto: campaginPhoto,
      Model: model,
      KPI: KPI,
      CAP: CAP,
      paymentterms: paymentterms,
      payouts: {
        Registration: event1_val,
        FTD: event2_val,
        Qualified: event3_val,
        Deposit: event4_val,
      },
    };

    // console.log('Add Campagin clicked with data:', data);

    const url = `${URL}/campaign/?api_key=${API_KEY}`;
    const res = await axios.post(url, data);
    console.log("Add Campagin response only", res);
    console.log("Add Campagin response.data this is:", res.data);
    if (res?.status === 200) {
      toast.success("Campagin Added Successfully");

      setName("");
      setDesc("");
      setCountry("");
      setCategory("");
      setImageUrl("");
      setStatusCampagin("");
      setTypeoffer("");
      setSelectedAdvitisor_id("");
      setOffer_url("");
      handleClose();
      fetchData();
      setDeposit("");
      setDepositLarge("");
      setRegistration("");
      setFTD("");
      setModel("");
      setKPI("");
      setCAP("");
      setPaymentterms("");
      setQualified("");
      setEvent1_val("");
      setEvent2_val("");
      setEvent3_val("");
      setEvent4_val("");
    }
  };

  const advitisorDeatil = async () => {
    try {
      const url = `${URL}/api/advitisor/?api_key=${API_KEY}`;
      const res = await axios.get(url);
      // console.log("This is response from advitisor data", res?.data);
      setAdvitisorDeatils(res?.data);
    } catch (error) {}
  };

  const fetchData = async () => {
    try {
      console.log("status-->", status);
      const result = await axios.get(
        `${URL}/campaign/?page=1&status=${status}`
      );

      console.log("offers data -->", result);
      setData(result?.data);
      setLoading(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleDeleteCampaign = async (campaign) => {
    try {
      const id = campaign?._id;
      const url = `${URL}/campaign/${id}?api_key=${API_KEY}`;
      const res = await axios.delete(url);

      if (res?.status === 200) {
        toast.success("Campaign Deleted Successfully");
        fetchData();
        getArchiveCampaign();
        setShowDeleteConfirmation(false);
      }
    } catch (error) {
      console.error("Error Deleting Campaign:", error);
      toast.error("Error Deleting Campaign");
    }
  };

  const handleEditCampagin = (data) => {
    console.log("edit campagin data latest-->", data);
    setNewData({
      campaign_id: data?._id,
      advitisor_id: data?.advitisor_id,
      name: data?.name,
      category: data?.category,
      url: data?.url,
      CampaignPhoto: data?.image_url,
      description: data?.description,
      image_url: data?.image_url,
      status: data?.status,
      type: data?.type,
      vertical_image_url: data?.vertical_image_url,
      horizontal_image_url: data?.horizontal_image_url,
      model: data?.model,
      KPI: data?.KPI,
      CAP: data?.CAP,
    });

    setShowEditCampaignModal(true);
  };

  const updateCampaign = async () => {
    try {
      const url = `${URL}/campaign/?api_key=${API_KEY}`;
      const res = await axios.put(url, newData);

      if (res?.status === 200) {
        toast.success("Campaign Updated Successfully");
        fetchData(); // Fetch updated data after successful update
        setShowEditCampaignModal(false); // Close the modal after successful update
      }
    } catch (error) {
      console.error("Error updating campaign:", error);
      toast.error("Error updating campaign");
    }
  };

  const uploadCampaginPhoto = async (campaign_id) => {
    try {
      if (selectedPhoto) {
        const apiUrl = `${URL}/campaign/upload-photo/${campaign_id}?api_key=${API_KEY}`;

        const formData = new FormData();
        formData.append("photo", selectedPhoto);

        const res = await axios.post(apiUrl, formData);

        if (res?.status === 200) {
          toast.success(res?.data?.message);
          setSelectedPhoto(null);
          fetchData();
        }
      } else {
        console.warn("No photo selected for upload.");
      }
    } catch (error) {
      console.error("Error uploading photo:", error);
      toast.error(error?.response?.data?.detail);
    }
  };
  const getArchiveCampaign = async () => {
    const apiUrl = `${URL}/campaign/archived?page=1`;

    try {
      const response = await axios.get(apiUrl);
      setCampaignData(response.data);
    } catch (error) {
      console.error("Error fetching archive campaign data:", error);
    }
  };

  const handleToggleArchive = async (campaign) => {
    try {
      // Get the current archived status of the campaign
      const currentStatus = campaign.archived;
  
      // Calculate the new archived status by toggling the current status
      const newStatus = !currentStatus;
  
      // Construct the URL for the API endpoint with the new status
      const url = `${URL}/campaign/archive/${campaign._id}?archived_status=${newStatus}&api_key=${API_KEY}`;
  
      // Make a PUT request to update the archive status
      const res = await axios.put(url);
  
      if (res.status === 200) {
        // If successful, update the campaign data by fetching the updated list of archived campaigns
        getArchiveCampaign();
        fetchData()
        // Display a success toast message
        toast.success(`Campaign ${currentStatus ? "unarchived" : "archived"} successfully`);
      } else {
        // Handle error response
        toast.error("Failed to update campaign archive status");
      }
    } catch (error) {
      // Handle error
      console.error("Error toggling archive status:", error);
      toast.error("Failed to update campaign archive status");
    }
  };
  
  useEffect(() => {
    getArchiveCampaign();
  }, []);

  useEffect(() => {
    const startIndex = page * rowsPerPage;
    const dataForPage = data.slice(startIndex, startIndex + rowsPerPage);
    const dataForPage2 = campaignData?.slice(
      startIndex,
      startIndex + rowsPerPage
    );
    setPageData(isArchive ? dataForPage2 : dataForPage);
  }, [page, rowsPerPage, data, isArchive, campaignData]);

  const toggleArchive = () => {
    setIsArchive((prev) => !prev);
  };
  function shortenDescription(description, maxLenght = 50) {
    if (description.length <= maxLenght) {
      return description;
    } else {
      return description.substring(0, maxLenght) + "...";
    }
  }
  return (
    <>
      <div
        style={{
          width: "100%",
          padding: "10px",
          marginLeft: "3%",
          height: "100%",
        }}
      >
        <Link to="/create-campaign">
          <Button sx={{ margin: "10px" }} variant="contained" color="success">
            <AddIcon /> Add Campaign
          </Button>
        </Link>

        <FormControl sx={{ width: 200 }}>
          <InputLabel id="demo-simple-select-label">All Offers</InputLabel>
          <Select
            defaultValue=""
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={status}
            onChange={handleStatusChange}
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="paused">Paused</MenuItem>
            <MenuItem value="expired">Expired</MenuItem>
            <MenuItem value="active">Active</MenuItem>
          </Select>
        </FormControl>
        <Button
          sx={{ margin: "10px", display: "inline-block" }}
          onClick={toggleArchive}
          variant="contained"
          color={isArchive ? "secondary" : "primary"}
        >
          {isArchive ? "Hide Archive" : "Show Archive"}
        </Button>
        <TableContainer component={Paper}>
          <Table
            id="offers-table"
            sx={{ minWidth: 550 }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">Photo</TableCell>
                <TableCell align="center">Offers</TableCell>
                <TableCell align="center">Category</TableCell>
                <TableCell align="center">Description</TableCell>
                <TableCell align="center">Country</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Edit</TableCell>
                <TableCell align="center">Upload Photo</TableCell>
                {isArchive && (
                  <TableCell align="center">Archive Status</TableCell>
                )}
                 {!isArchive && (
                  <TableCell align="center">Archive Status</TableCell>
                )}
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                pageData?.length > 0 ? (
                  pageData.map((row, i) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="td" scope="row">
                        <img
                          style={{ width: "100px", height: "100px" }}
                          src={row?.CampaignPhoto}
                          alt="CampaignPhoto"
                        />
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="center">
                        {row?.category === null ? "N/A" : row?.category}
                      </TableCell>
                      <TableCell align="center">
                        {row?.description === null
                          ? "N/A"
                          : shortenDescription(row?.description)}
                      </TableCell>
                      <TableCell align="center">
                        {row?.country === null ? "N/A" : row?.country}
                      </TableCell>
                      <TableCell align="center">
                        {row?.status === "active" ? (
                          <CloudDoneIcon style={{ color: "#32e620" }} />
                        ) : row?.status === "paused" ? (
                          <PauseIcon style={{ color: "#FF0000" }} />
                        ) : row?.status === "expired" ? (
                          <AccessTimeIcon style={{ color: "#FFA500" }} />
                        ) : null}
                        {"   "}
                        <span style={{ fontWeight: 700 }}>{row?.status} </span>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant="contained"
                          onClick={() => handleEditCampagin(row)}
                        >
                          Edit Camapgin
                        </Button>
                      </TableCell>

                      <TableCell align="center">
                        <div className="d-flex flex-column align-items-center justify-content-center  ">
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handlePhotoChange}
                          />
                          <Button
                            className="mt-2"
                            variant="contained"
                            onClick={() => uploadCampaginPhoto(row._id)}
                          >
                            Upload Photo
                          </Button>
                        </div>
                      </TableCell>

                      {!isArchive && (
                        <TableCell align="center">
                          {row.archived ? (
                            <Button onClick={() => handleToggleArchive(row)}>
                              Unarchive
                            </Button>
                          ) : (
                            <Button onClick={() => handleToggleArchive(row)}>
                              Archive
                            </Button>
                          )}
                        </TableCell>
                      )}

                      {isArchive && (
                        <TableCell align="center">
                          {row.archived ? (
                            <Button onClick={() => handleToggleArchive(row)}>
                              Unarchive
                            </Button>
                          ) : (
                            <Button onClick={() => handleToggleArchive(row)}>
                              Archive
                            </Button>
                          )}
                        </TableCell>
                      )}

                      <TableCell align="center">
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => showDeleteConfirmationDialog(row)}
                        >
                          Delete Campaign
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : null
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>

        <Modal onClose={() => setIsPayoutVal(false)} open={isPayoutVal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "white",
              boxShadow: 24,
              p: 2,
              borderRadius: "8px",
              width: "90%",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="text-center">Reg</TableCell>
                  <TableCell className="text-center">Ftd</TableCell>
                  <TableCell className="text-center">deposite</TableCell>
                  <TableCell className="text-center">Deposite</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow style={{ backgroundColor: "#f2f2f2" }}>
                  <TableCell className="text-center">
                    {payoutVal?.reg ? payoutVal?.reg : "N/A"}
                  </TableCell>
                  <TableCell className="text-center">
                    {payoutVal?.ftd ? payoutVal?.ftd : "N/A"}
                  </TableCell>
                  <TableCell className="text-center">
                    {payoutVal?.deposit ? payoutVal?.deposit : "N/A"}
                  </TableCell>
                  <TableCell className="text-center">
                    {payoutVal?.Deposit ? payoutVal?.Deposit : "N/A"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Modal>

        <Modal
          show={showEditCampaignModal}
          handleClose={() => setShowEditCampaignModal(false)}
        >
          <Modal.Header
            closeButton
            onClick={() => setShowEditCampaignModal(false)}
          >
            <Modal.Title>+ Edit Campaign</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={newData.url}
                  placeholder="Enter URL"
                  onChange={(event) =>
                    setNewData({ ...newData, url: event.target.value })
                  }
                />
              </FormControl>
              <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <TextField
                  fullWidth
                  placeholder="Enter Description"
                  variant="outlined"
                  value={newData.description}
                  onChange={(event) =>
                    setNewData({ ...newData, description: event.target.value })
                  }
                />
              </FormControl>

              <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <TextField
                  fullWidth
                  placeholder="Enter Image URL"
                  variant="outlined"
                  value={newData.image_url}
                  onChange={(event) =>
                    setNewData({ ...newData, image_url: event.target.value })
                  }
                />
              </FormControl>
              <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <TextField
                  fullWidth
                  placeholder="Enter Horizontal Img URL"
                  variant="outlined"
                  value={newData.horizontal_image_url}
                  onChange={(event) =>
                    setNewData({
                      ...newData,
                      horizontal_image_url: event.target.value,
                    })
                  }
                />
              </FormControl>
              <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <TextField
                  fullWidth
                  placeholder="Enter Vertical Img URL"
                  variant="outlined"
                  value={newData.vertical_image_url}
                  onChange={(event) =>
                    setNewData({
                      ...newData,
                      vertical_image_url: event.target.value,
                    })
                  }
                />
              </FormControl>

              <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <InputLabel>Select Category</InputLabel>
                <Select
                  value={newData?.category}
                  onChange={(event) =>
                    setNewData({ ...newData, category: event.target.value })
                  }
                  placeholder="Select Category"
                >
                  <MenuItem value="Ecommerce">Ecommerce</MenuItem>
                  <MenuItem value="BFSI">BFSI</MenuItem>
                  <MenuItem value="Banking">Banking</MenuItem>

                  <MenuItem value="Casino">Casino</MenuItem>
                  <MenuItem value="CPL">CPL</MenuItem>
                  <MenuItem value="CPR">CPR</MenuItem>
                  <MenuItem value="CPS">CPS</MenuItem>
                  <MenuItem value="CPD">CPD</MenuItem>

                  <MenuItem value="Gambling">Gambling</MenuItem>
                  <MenuItem value="Crypto">CPD</MenuItem>
                  <MenuItem value="Survey">Survey</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <InputLabel>Status</InputLabel>
                <Select
                  value={newData.status}
                  placeholder="Select Status"
                  onChange={(event) =>
                    setNewData({ ...newData, status: event.target.value })
                  }
                  variant="outlined"
                >
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="paused">Paused</MenuItem>
                  <MenuItem value="expired">Expired</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <InputLabel>Type</InputLabel>
                <Select
                  value={newData?.type}
                  placeholder="Select Type"
                  onChange={(event) =>
                    setNewData({ ...newData, type: event.target.value })
                  }
                  variant="outlined"
                >
                  <MenuItem value="Public">Public</MenuItem>
                  <MenuItem value="Private">Private</MenuItem>
                </Select>
              </FormControl>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="contained"
              color="error"
              onClick={() => setShowEditCampaignModal(false)}
            >
              Close
            </Button>
            <Button
              sx={{ margin: "10px" }}
              variant="contained"
              color="success"
              onClick={updateCampaign}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showDeleteConfirmation}
          onHide={() => setShowDeleteConfirmation(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm Deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete this campaign?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outlined"
              color="error"
              onClick={() => setShowDeleteConfirmation(false)}
            >
              Cancel
            </Button>
            <Button
              sx={{ marginLeft: "10px" }}
              variant="contained"
              color="error"
              onClick={() => handleDeleteCampaign(selectedCampaignForDeletion)}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <Toaster />
    </>
  );
};

export default Offers;
