// SidebarToggleButton.js
import React, { useState } from "react";
import { Button } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import SidebarMenu from "./SidebarMenu";
import SideBar from "./SideBar";




const SidebarToggleButton = (children) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);


    return (
        <>

            <Button variant="contained" style={{ backgroundColor: "#00073d" }}  >
                {/* <SideBar isOpen={toggle} /> */}
                <MenuIcon />
            </Button>

        </>
    );
};

export default SidebarToggleButton;
